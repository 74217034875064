/* .form-group input.form-control {
  padding-left: 40px;
} */

.customInput {
  padding-left: 40px;
}

.form-group > i {
  font-size: 1.125rem;
  position: absolute;
  left: 12px;
  top: 33px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}

.rtl .authModal {
  text-align: right;
}
