.carouselSlider {
  direction: "initial";
}
/* 
  .imgContainer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  } */
